import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@hotwired/turbo-rails';

// Import and start extra dependencies
import '../scripts';
import { scrollToInvalid } from '../scripts/scroll';

// Setup Alpine and components
import Alpine from 'alpinejs';
import autocomplete from '../alpine/auto-complete';
import autoFlag from '../alpine/auto-flag';
import autoUpdate from '../alpine/auto-update';
import clipboard from '../alpine/clipboard';
import combobox from '../alpine/combobox';
import dropdown from '../alpine/dropdown';
import fileUpload from '../alpine/file-upload';
import googleMapsAdmin from '../alpine/google-maps-admin';
import modal from '../alpine/modal';
import sidebar from '../alpine/sidebar';
import sortableList from '../alpine/sortable-list';
import sortableHomepage from '../alpine/sortable-homepage';
import projectSettings from '../alpine/project-settings';
import beforeUnload from '../alpine/before-unload';
import projectPath from '../alpine/project-path';
import settingsWizard from '../alpine/settings-wizard';

window.Alpine = Alpine;
Alpine.data('autocomplete', autocomplete);
Alpine.data('autoFlag', autoFlag);
Alpine.data('autoUpdate', autoUpdate);
Alpine.data('clipboard', clipboard);
Alpine.data('combobox', combobox);
Alpine.data('dropdown', dropdown);
Alpine.data('fileUpload', fileUpload);
Alpine.data('googleMapsAdmin', googleMapsAdmin);
Alpine.data('modal', modal);
Alpine.data('sidebar', sidebar);
Alpine.data('sortableList', sortableList);
Alpine.data('sortableHomepage', sortableHomepage);
Alpine.data('projectSettings', projectSettings);
Alpine.data('beforeUnload', beforeUnload);
Alpine.data('projectPath', projectPath);
Alpine.data('settingsWizard', settingsWizard);

document.addEventListener('DOMContentLoaded', () => {
  Alpine.start();
  scrollToInvalid();
});
