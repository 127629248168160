import { trapFocus } from '~/scripts/trap-focus';

export default function modal(dynamicFocusableEls) {
  return {
    open: false,
    focusableEls: null,
    dynamicFocusableEls: dynamicFocusableEls || false,

    init() {
      // If the content of the modal is updated dynamically (e.g. via the x-show directive),
      // set dynamicFocusableEls to true and dynamically assign the focusable elements
      // to focusableEls.
      if (this.dynamicFocusableEls) {
        this.$watch('focusableEls', () => {
          trapFocus(this.$refs.panel, this.focusableEls);
        });
      } else {
        this.focusableEls = this.$refs.panel.querySelectorAll(
          'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
        );

        this.$watch('open', () => {
          trapFocus(this.$refs.panel, this.focusableEls);
        });
      }
    },

    overlay: {
      ['x-show']() {
        return this.open;
      },
      ['@keyup.esc.prevent']() {
        this.$refs.close.click();
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-300';
      },
      ['x-transition:enter-start']() {
        return 'opacity-0';
      },
      ['x-transition:enter-end']() {
        return 'opacity-100';
      },
      ['x-transition:leave']() {
        return 'ease-in duration-200';
      },
      ['x-transition:leave-start']() {
        return 'opacity-100';
      },
      ['x-transition:leave-end']() {
        return 'opacity-0';
      },
    },

    input: {
      ['x-on:keydown.enter.prevent']() {
        return true;
      },
    },

    close: {
      ['x-on:click']() {
        this.$dispatch('closeModal');
        if (!this.unsavedChanges) this.open = false;
      },
    },

    panel: {
      ['x-show']() {
        return this.open;
      },
      ['x-on:close']() {
        this.$dispatch('closeModal');
        if (!this.unsavedChanges) this.open = false;
      },
      ['x-on:click.outside']() {
        this.$refs.close.click();
      },
      ['@keyup.esc.prevent']() {
        this.$refs.close.click();
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-300';
      },
      ['x-transition:enter-start']() {
        return 'transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95';
      },
      ['x-transition:enter-end']() {
        return 'opacity-100 translate-y-0 sm:scale-100';
      },
      ['x-transition:leave']() {
        return 'ease-in duration-200';
      },
      ['x-transition:leave-start']() {
        return 'opacity-100 translate-y-0 sm:scale-100';
      },
      ['x-transition:leave-end']() {
        return 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95';
      },
    },
  };
}
