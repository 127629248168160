// NOTE(Robbe): For some reason this markers reference can't be kept inside Alpine's state
// If we keep track of the markers within Alpine, the old markers don't disappear on setMap(null)
const searchResultMarkers = [];

export default function googleMapsAdmin(originLatLng, mapParams, id, isProjectSettingsPage = false) {
  const mapSettings = mapParams.mapSettings;
  const mapOverlay = mapParams.mapOverlay;

  return {
    originLat: originLatLng.originLat,
    originLng: originLatLng.originLng,
    zoomLevel: mapSettings.zoomLevel,
    markerURL: mapParams.markerUrl || "https://bpart-default-assets.s3.eu-central-1.amazonaws.com/img/map-default-marker.png",
    overlayURL: mapOverlay.mapOverlayImageUrl,
    geoJson: mapParams.geoJson,
    showOverlay: mapOverlay.mapOverlayVisible,
    mapType: mapSettings.mapType || "roadmap",
    showImageError: false,
    showGeoJsonError: false,
    overlayCoords: {
      north: mapOverlay.overlayBoundNorth,
      south: mapOverlay.overlayBoundSouth,
      east: mapOverlay.overlayBoundEast,
      west: mapOverlay.overlayBoundWest,
    },
    groundOverlay: null,
    open: false,
    latInput: this.$refs.latInput,
    lngInput: this.$refs.lngInput,
    map: null,

    init() {
      // Initialize the map (asynchronously)
      this.initMap();

      // Watch for changes in the overlay visibility
      this.$watch('showOverlay', () => { this.renderOverlays(this.map); });
      this.$watch('overlayCoords', () => {
        if (this.overlayURL) {
          this.removeOverlayImage();
          this.renderOverlayImage(this.map);
        }
      });

      // Since deleting an overlay image triggers a turbo-frame render,
      // we listen to this event to remove the overlay image
      const turboFrame = this.$el.querySelector("turbo-frame");
      if (turboFrame) {
        turboFrame.addEventListener("turbo:before-frame-render", (e) => {
          if (this.overlayURL) {
            this.removeOverlayImage();
          }
        });
      }
    },

    async initMap() {
      // Load the Google Maps API libraries
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
      const { Autocomplete } = await google.maps.importLibrary("places");

      const lat = this.latInput?.value || this.originLat;
      const lng = this.lngInput?.value || this.originLng;
      const mapOptions = {
        zoom: this.zoomLevel || 14,
        center: { lat: parseFloat(lat), lng: parseFloat(lng) },
        mapTypeId: this.mapType,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
        gestureHandling: "cooperative",
        mapId: id,
      };

      // Create the map
      let map = new Map(this.$refs.map, mapOptions);
      this.map = map;

      // Add a search box
      if (this.$refs.searchBox) {
        const placeAutocomplete = new Autocomplete(this.$refs.searchBox);

        placeAutocomplete.addListener('place_changed', async (ev) => {
          let value = this.$refs.searchBox.value;
          await this.findPlaces(value, map, AdvancedMarkerElement);
        });
      }

      // Add a listener to update the zoom level input
      google.maps.event.addListener(map, 'zoom_changed', () => {
        if (this.$refs.mapZoom) this.$refs.mapZoom.value = map.zoom;
      });

      // Add a clickable or draggable marker to input a location (for entry or profile)
      // Draggable markers can be moved by using the keyboard. To activate dragging,
      // press Option + Space or Option + Enter (Mac), ALT + Space or Alt + Enter (Windows).
      if (this.latInput && this.lngInput) {
        const icon = document.createElement("img");
        icon.src = this.markerURL;

        const markerLatLng = new google.maps.LatLng(lat, lng);

        const marker = new AdvancedMarkerElement({
          map: map,
          position: markerLatLng,
          content: icon,
          gmpDraggable: true,
        });

        map.setCenter(markerLatLng);

        map.addListener('click', (mapsMouseEvent) => {
          const mapPosition = mapsMouseEvent.latLng;
          this.latInput.setAttribute('value', mapPosition.lat());
          this.lngInput.setAttribute('value', mapPosition.lng());
          marker.position = mapPosition;
        });

        marker.addListener('dragend', (evt) => {
          const markerPosition = marker.position;
          this.latInput.setAttribute('value', markerPosition.lat);
          this.lngInput.setAttribute('value', markerPosition.lng);
        });
      }

      // Add the overlay and geoJson to the map
      this.renderOverlays(map);
    },

    renderOverlays(map) {
      if (google && ((isProjectSettingsPage && this.showOverlay) || !isProjectSettingsPage)) {
        if (this.overlayURL) this.renderOverlayImage(map);
        if (this.geoJson) this.renderGeoJson(map);
      } else {
        this.removeOverlayImage();
        this.removeGeoJson();
      }
    },

    // Overlay Image

    setOverlayImageURL(url) {
      if (url) {
        this.overlayURL = url
      } else {
        this.overlayURL = this.$el.files[0] ? URL.createObjectURL(this.$el.files[0]) : '';
      };
    },

    renderOverlayImage(map) {
      //Extend the Overlay class so we can add a ground overlay to the map
      class MapOverlay extends google.maps.OverlayView {
        constructor(bounds, image) {
          super();
          this.bounds = bounds;
          this.image = image;
        }
        //  onAdd is called when the map's panes are ready and the overlay has been added to the map.
        onAdd() {
          this.div = document.createElement('div');
          this.div.style.borderStyle = 'none';
          this.div.style.borderWidth = '0px';
          this.div.style.position = 'absolute';
          // Create the img element and attach it to the div.
          const img = document.createElement('img');
          img.src = this.image;
          img.style.width = '100%';
          img.style.height = '100%';
          img.style.position = 'absolute';
          this.div.appendChild(img);
          // Add the element to the "overlayLayer" pane.
          const panes = this.getPanes();
          panes.overlayLayer.appendChild(this.div);
        }
        draw() {
          // We use the south-west and north-east coordinates of the overlay to peg it to the correct position and size.
          // To do this, we need to retrieve the projection from the overlay.
          const overlayProjection = this.getProjection();
          // Retrieve the south-west and north-east coordinates of this overlay in LatLngs and convert them to pixel coordinates.
          // We'll use these coordinates to resize the div.
          const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
          const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

          // Resize the image's div to fit the indicated dimensions.
          if (this.div) {
            this.div.style.left = sw.x + 'px';
            this.div.style.top = ne.y + 'px';
            this.div.style.width = ne.x - sw.x + 'px';
            this.div.style.height = sw.y - ne.y + 'px';
          }
        }
        //  The onRemove() method will be called automatically from the API if we ever set the overlay's map property to 'null'.
        onRemove() {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            delete this.div;
          }
        }
      }
      if (checkOverlayValidity(this.overlayBounds(), this.overlayURL)) {
        this.showImageError = false;
        if (!google || !this.$refs.map) return;

        this.groundOverlay = new MapOverlay(this.overlayBounds(), this.overlayURL);
        this.groundOverlay.setMap(map);
      } else {
        this.showImageError = true;
      }
    },

    overlayBounds() {
      const overlayNorth = parseFloat(this.overlayCoords.north);
      const overlaySouth = parseFloat(this.overlayCoords.south);
      const overlayEast = parseFloat(this.overlayCoords.east);
      const overlayWest = parseFloat(this.overlayCoords.west);

      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(overlaySouth, overlayWest),
        new google.maps.LatLng(overlayNorth, overlayEast)
      );
      return bounds;
    },

    previewOverlayImage(url = null) {
      this.removeOverlayImage();
      this.setOverlayImageURL(url);
      if (this.overlayURL) this.renderOverlayImage(this.map);
    },

    removeOverlayImage() {
      this.groundOverlay && this.groundOverlay.setMap(null);
    },

    // GeoJSON

    setGeoJson() {
      const file = this.$el.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          this.geoJson = reader.result;
          this.renderGeoJson(this.map);
        };
      }
    },

    removeGeoJson() {
      this.map.data.forEach((feature) => this.map.data.remove(feature));
    },

    renderGeoJson(map) {
      if (checkGeoJsonValidity(this.geoJson)) {
        this.showGeoJsonError = false;
        if (!google || !this.$refs.map) return;

        map.data.addGeoJson(JSON.parse(this.geoJson));
        map.data.setStyle((feature) => {
          return {
            fillColor: feature.getProperty('fill'),
            fillOpacity: feature.getProperty('fill-opacity'),
            strokeColor: feature.getProperty('stroke'),
            strokeOpacity: feature.getProperty('stroke-opacity'),
            strokeWeight: feature.getProperty('stroke-width'),
            title: feature.getProperty('title'),
            clickable: false
          };
        });
      } else {
        this.showGeoJsonError = true;
      }
    },

    previewGeoJson() {
      this.removeGeoJson();
      this.setGeoJson();
    },


    async findPlaces(searchTerm, map, AdvancedMarkerElement) {
      const { Place } = await google.maps.importLibrary("places");
      const request = {
        textQuery: searchTerm,
        fields: ["displayName", "location"],
      };

      if (searchResultMarkers.length) this.removeSearchResultMarkers();

      const { places } = await Place.searchByText(request);

      if (places.length) {
        const { LatLngBounds } = await google.maps.importLibrary("core");
        const bounds = new LatLngBounds();

        // Loop through and get all the results.
        places.forEach((place) => {
          const searchResultMarker = new AdvancedMarkerElement({
            map: map,
            position: place.location,
            title: place.displayName,
          });
          searchResultMarkers.push(searchResultMarker);

          bounds.extend(place.location);
        });

        // zoom to the bounds if there are multiple places
        if (places.length > 1) {
          map.fitBounds(bounds);
        } else {
          map.setCenter(bounds.getCenter());
          map.setZoom(this.zoomLevel || 14);
        };
      } else {
        console.log("No results");
      }
    },

    removeSearchResultMarkers() {
      searchResultMarkers.forEach((marker) => marker.setMap(null));
    },

    searchInput: {
      ['@keydown.enter.prevent']() {
        // Just here to prevent enter from submitting the form
      },
    },
  };
};

const checkOverlayValidity = (bounds, overlayURL) => {
  if (bounds === false) return false;

  const overlayBounds = bounds.toJSON();
  const areImageBoundsValid = Object.values(overlayBounds).every((coord) => coord > 0);
  const isImageUrlValid = overlayURL?.length > 0;

  return areImageBoundsValid && isImageUrlValid;
};

const checkGeoJsonValidity = (geoJson) => {
  if (geoJson === '') return false;

  const geoJsonObj = JSON.parse(geoJson);
  // For definition of geoJson, see https://datatracker.ietf.org/doc/html/rfc7946#section-1.4
  const typeList = ["Feature", "FeatureCollection", "Point", "MultiPoint", "LineString", "MultiLineString", "Polygon", "MultiPolygon", "GeometryCollection"]

  return typeList.includes(geoJsonObj.type);
};
